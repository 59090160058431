import React, { useEffect, useRef, useState } from 'react';
import { graphql } from "gatsby"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import parse from 'html-react-parser';
import Layout from "../../components/layout";
import Seo from "../../components/seo";

//sections
import EvocalizeHeroSection from '../sections/caseStudies/evocalize/EvocalizeHeroSection';
import ChallengesSection from '../sections/caseStudies/ChallengesSection';
import EvocalizeSection1 from '../sections/caseStudies/evocalize/EvocalizeSection1';
import EvocalizeSection2 from '../sections/caseStudies/evocalize/EvocalizeSection2';
import EvocalizeSection3 from '../sections/caseStudies/evocalize/EvocalizeSection3';

const Evocalize = ({ data }) => {
  const mainData = data.allWpPage.nodes[0].caseStudyEvocalizePageACF;
  console.log(mainData);
  const sectionRef = useRef();
  const [bgTransition, setBgTransition] = useState('');
  return (
    <Layout headerClass="white-header">
      <Seo title="Evocalize" />
      <div className={"evocalize-case-study page-wrapper " + bgTransition} id="evocalize-casestudy-page-wrapper" ref={sectionRef}>
        <EvocalizeHeroSection 
            preTitle={parse(mainData.evocalizeHeroSectionPretitle)}
            mainTitle={parse(mainData.evocalizeHeroSectionTitle)}
            image1x={
              {
                sourceUrl: mainData.evocalizeHeroSectionImage1x.sourceUrl,
                altText: mainData.evocalizeHeroSectionImage1x.altText,
                width: mainData.evocalizeHeroSectionImage1x.width,
              }
            }
            image2x={
              {
                sourceUrl: mainData.evocalizeHeroSectionImage2x.sourceUrl,
                altText: mainData.evocalizeHeroSectionImage2x.altText,
                width: mainData.evocalizeHeroSectionImage2x.width,
              }
            }
            mobileImage={
              {
                sourceUrl: mainData.evocalizeHeroSectionMobileImage.sourceUrl,
                altText: mainData.evocalizeHeroSectionMobileImage.altText,
                width: mainData.evocalizeHeroSectionMobileImage.width,
              }
            }
        />
        <ChallengesSection 
            companyInfoList={mainData.evocalizeChallengeSectionCompanyInfoList}
            challengesList={mainData.evocalizeChallengeSectionChallangesList}
        />
        <EvocalizeSection1 
            mainTitle={parse(mainData.evocalizeSection1Title)}
            mainDescription={parse(mainData.evocalizeSection1Description)}
            imageslist={mainData.evocalizeSection1ImageList}
        />
        <EvocalizeSection2 
            mainTitle={parse(mainData.evocalizeSection2Title)}
            mainDescription={parse(mainData.evocalizeSection2Description)}
            imageslist={mainData.evocalizeSection2ImageList}
            imageslist2={mainData.evocalizeSection2ImageList2}
        />
        <EvocalizeSection3 
            mainTitle={parse(mainData.evocalizeSection3Title)}
            image1x={
              {
                sourceUrl: mainData.evocalizeSection3Image1x.sourceUrl,
                altText: mainData.evocalizeSection3Image1x.altText,
                width: mainData.evocalizeSection3Image1x.width,
              }
            }
            image2x={
              {
                sourceUrl: mainData.evocalizeSection3Image2x.sourceUrl,
                altText: mainData.evocalizeSection3Image2x.altText,
                width: mainData.evocalizeSection3Image2x.width,
              }
            }
            imageslist={mainData.evocalizeSection3ImageList}
            imageslist2={mainData.evocalizeSection3ImageList2}
        />
      </div>
    </Layout>
  )
}



export const EvocalizeQuery = graphql`

{
  allWpPage(filter: {slug: {eq: "evocalize"}}) {
      nodes {
        caseStudyEvocalizePageACF {
            evocalizeHeroSectionPretitle
            evocalizeHeroSectionTitle
            evocalizeHeroSectionImage1x {
              altText
              sourceUrl
              width
            }
            evocalizeHeroSectionImage2x {
              altText
              sourceUrl
              width
            }
            evocalizeHeroSectionMobileImage {
                altText
                sourceUrl
                width
            }
            evocalizeChallengeSectionCompanyInfoList {
              title
              description
            }
            evocalizeChallengeSectionChallangesList {
              title
              description
            }

            evocalizeSection1Title
            evocalizeSection1Description
            evocalizeSection1ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
            evocalizeSection2Title
            evocalizeSection2Description
            evocalizeSection2ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
            evocalizeSection2ImageList2 {
              image1x {
                altText
                sourceUrl
                width
              }
            }
            evocalizeSection3Title
            evocalizeSection3Image1x {
              altText
              sourceUrl
              width
            }
            evocalizeSection3Image2x {
              altText
              sourceUrl
              width
            }
            evocalizeSection3ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
            evocalizeSection3ImageList2 {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
        }
      }
    }
}

`

export default Evocalize